// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-remote-control-remote-js": () => import("./../../../src/pages/remote-control/remote.js" /* webpackChunkName: "component---src-pages-remote-control-remote-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-showcase-remote-js": () => import("./../../../src/pages/showcase/remote.js" /* webpackChunkName: "component---src-pages-showcase-remote-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

